import React from 'react';
import { useNavigate } from 'react-router-dom';

import { i18n } from '../translate/i18n'

import tutorialImg from '../images/tutorial.gif'

import './tutorial.css'

function Tutorial() {
  const navigate = useNavigate();

  return (
    <div className='container-tutorial'>
      <div className='content'>
        <span>{i18n.t('tutorial.title')}</span>
        <img src={tutorialImg} alt='tutorial'/>
        <button className='result-button' onClick={() => navigate('/snap')}>
          {i18n.t('tutorial.buttonTitle')} <br />
          {i18n.t('tutorial.subButtonTitle')}
        </button>
      </div>
    </div>
  );
}

export default Tutorial;