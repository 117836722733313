const messages = {
  en: {
    translations: {
      home: {
        title: 'Scan a pentagram',
        subTitle: 'and get ',
        subSubTitle: 'access to hell.',
        subSubSubTitle: '',
        desktopDescription: 'Desktops don’t have the power to summon evil. Come back to hell on your smartphone.',
        description: 'You can draw one',
        descriptionOne: 'or use any existing',
        descriptionTwo: 'pentagram',
        descriptionThree: 'to summon diablo.',
        descriptionFor: '',
        buttonLabel: 'Summon Diablo now',
        subButtonLabel: ''
      },
      summoning: {
        title: 'point your cell phone to the pentagram',
        loading: 'summoning'
      },
      result: {
        title: 'It’s ready',
        subTitle: 'to be summoned',
        subSubTitle: 'in your',
        subSubTitleTwo: 'smartphone.',
        button: 'SUMMON NOW',
        subButton: ''
      },
      resultFailed: {
        title: 'Bad people',
        subTitle: 'go to hell,',
        subSubTitle: 'bad pentagrams, don’t.',
        button: 'summon again',
        subButton: '',
        secondaryButton: 'go to store',
        secondaryButtonSubTitle: ''
      },
      menu: {
        useTerms: 'Terms of Use'
      },
      tutorial: {
        title: 'How to draw a pentagram:',
        buttonTitle: 'Summon Diablo now',
        subButtonTitle: ''
      }
    }
  }
}

export { messages }