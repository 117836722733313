import { useNavigate } from 'react-router-dom';

import { i18n } from '../translate/i18n'

import pentagramaBrifiengImg from '../images/pentagramaBrifieng.svg'
import qrCodeImg from '../images/qrcode.svg'
import { eventTrack } from '../utils/google-events';

function Home() {
  const navigate = useNavigate();
  const width = window.innerWidth;

  const handleClickButton = () => {
    navigate('/snap');
    eventTrack('tela principal', 'clique botão', 'Invocar-diablo')
  }

  return (
    <div className="App">
      {width >= 1024 ? (
        <div className='briefing-container-desktop'>
          <div className='briefing-content'>
            <img src={pentagramaBrifiengImg} alt='pentagrama'/>
            <span>{i18n.t('home.desktopDescription')}</span> 
          </div>  
      
          <button>
            <img src={qrCodeImg} alt='qrcode'/>
          </button>
        </div>
      ) : (
        <div className='briefing-container'>
          <img src={pentagramaBrifiengImg} alt='pentagrama'/>
          <div className='briefing-content'>
            <span>
              {i18n.t('home.title')} <br />
              {i18n.t('home.subTitle')} <br />
              {i18n.t('home.subSubTitle')} <br />
              {i18n.t('home.subSubSubTitle')}
            </span> 
            <p>
              {i18n.t('home.description')} <br />
              {i18n.t('home.descriptionOne')} <br />
              {i18n.t('home.descriptionTwo')} <br />
              {i18n.t('home.descriptionThree')} <br />
              {i18n.t('home.descriptionFor')}
            </p>
          </div>  
      
          <button onClick={handleClickButton}>
            {i18n.t('home.buttonLabel')} <br />
            {i18n.t('home.subButtonLabel')}
          </button>
        </div>
      )}
    </div> 
  );
}

export default Home;