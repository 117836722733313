import ReactGA from 'react-ga';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import history from "./services/history";

import Home from './components/Home'
import Result from './components/Result'
import Download from './components/Download'
import Tutorial from './components/Tutorial'
import Camera from './components/Camera'
import Header from './components/Header'

import './index.css';

const trackingId = "UA-229638668-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const papyrusStyle = [
  "font-family: papyrus",
  "font-size: 30px",
  "color: peru",
].join(';');
console.log("%c Welcome to hell 🔥",
papyrusStyle);

console.log(`This is a browser feature aimed at developers. If someone told you to copy and paste something here to activate a Diablo feature or "hack" someone else's account, that's a scam and you'll give them access to your personal information.`);

function App() {
  return (
    <Router history={history}>
      <Header />
      <Routes>
        <Route element={<Home />} path="/" exact></Route>
        <Route element={<Camera />} path="/snap" exact></Route>
        <Route element={<Result />} path="/snap/result"></Route>
        <Route element={<Download />} path="/snap/result/download"></Route>
        <Route element={<Tutorial />} path="/snap/result/tutorial"></Route>
      </Routes>
    </Router>
  )
}

export default App;
