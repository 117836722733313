const messages = {
  pt: {
    translations: {
      home: {
        title: 'Aponte',
        subTitle: 'a câmera pra qualquer',
        subSubTitle: 'pentagrama',
        subSubSubTitle: 'e acesse o inferno.',
        desktopDescription: 'Computadores não têm o poder de invocar o mal. Volte para o inferno em seu smartphone.',
        description: 'Você pode',
        descriptionOne: 'desenhar o seu',
        descriptionTwo: 'ou usar',
        descriptionThree: 'um pentagrama',
        descriptionFor: 'que já exista.',
        buttonLabel: 'Invocar Diablo agora',
        subButtonLabel: ''
      },
      summoning: {
        title: 'aponte seu celular para o pentagrama',
        loading: 'invocando'
      },
      result: {
        title: 'O Diablo Immortal',
        subTitle: 'está pronto ',
        subSubTitle: 'pra ser invocado',
        subSubTitleTwo: 'no seu celular.',
        button: 'INVOCAR AGORA',
        subButton: ''
      },
      resultFailed: {
        title: 'Pessoas ruins',
        subTitle: 'vão pro inferno,',
        subSubTitle: 'pentagramas ruins, não.',
        button: 'Tente invocar novamente',
        subButton: '',
        secondaryButton: 'Ir  direto para a loja',
        secondaryButtonSubTitle: ''
      },
      menu: {
        useTerms: 'Termos de uso'
      },
      tutorial: {
        title: 'Como desenhar um pentagrama:',
        buttonTitle: 'Invocar Diablo agora',
        subButtonTitle: '',
      }
    }
  }
}

export { messages }