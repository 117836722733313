import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'

import { I18N_STORAGE_KEY } from '../utils/constants';
import { i18n } from '../translate/i18n'

import logoImg from '../images/logo.svg'
import menuHamburguerImg from '../images/menuHamburguer.svg'

import './header.css'
import { eventTrack } from '../utils/google-events';

function Header() {
  const [language] = useState(localStorage.getItem(I18N_STORAGE_KEY));
  const [isOpen] = useState(false);
  const width = window.innerWidth;

  const defaultLang = navigator.language
  const navigate = useNavigate();

  const handleSelectChange = event => {
    localStorage.setItem(
      I18N_STORAGE_KEY,
      event.target.value
    )
    // eslint-disable-next-line no-self-assign
    window.location = window.location
  }

  const isMenuOpen = () => {
    eventTrack('tela principal', 'abrir menu', 'menu-suspenso')
    return isOpen;
  };

  const handleGoogleEventMenu = (category, action, label) => {
    eventTrack(category, action, label)
  }

  return (
    <div className='container-header'>
      <Menu 
        crossButtonClassName={'cross-button'}
        // customCrossIcon={<img src={backMenuImg} alt="back-button" />}  
        onStateChange={isMenuOpen}
        burgerButtonClassName={ "my-burguer" } 
        menuClassName={ "my-menu" } 
        customBurgerIcon={ <img className='hamburguer' src={menuHamburguerImg} alt="menu" /> }
        width={'100%'}
      >
        <span>Menu</span>
        <a id="home" className="menu-item" onClick={handleGoogleEventMenu.bind(this, 'tela menu', 'clique menu', 'diablo')} href='/'>Diablo</a>
        {width < 1024 && <a id="tutorial" className="menu-item" onClick={handleGoogleEventMenu.bind(this, 'tela menu', 'clique menu', 'tutorial')} href='/snap/result/tutorial'>Tutorial</a>}
        <a id="termos" className="menu-item" onClick={handleGoogleEventMenu.bind(this, 'tela menu', 'clique menu', 'termos')} href='https://www.blizzard.com/pt-br/legal/fba4d00f-c7e4-4883-b8b9-1b4500a402ea/blizzard-end-user-license-agreement'>{i18n.t('menu.useTerms')}</a>
      </Menu>
      <img className='logo-home' src={logoImg} alt="logoHome" onClick={() => navigate('/')}/>
      <div className='select-wrapper'>
        <select defaultValue={defaultLang} onChange={handleSelectChange} value={language}>
          <option value="pt-BR">PT</option>
          <option value="en-US">EN</option>
          <option value="es-ES">ES</option>
        </select>
      </div>
    </div>
  );
}

export default Header;