import { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Webcam from "react-webcam";

import api from '../services/api';
import { i18n } from '../translate/i18n'

import pentagramaImg from '../images/pentagrama.svg'
import logoDiabloImg from '../images/logoDiablo.svg'
import { eventTrack } from '../utils/google-events';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "environment",
};

const Camera = () => {
  const [isLoading, setIsLoading] = useState(false);

  const webcamRef = useRef(null);
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onImageChange = (image) => {
    setIsLoading(true)

    fetch(image)
      .then(res => res.blob())
      .then(blob => {
        const image = new File([blob], "File",{ type: "image/png" })

        const file = new FormData();
        file.append("file", image, "file.png");

        api.post("/predict/image", file, {
          headers: {
            "Content-Type": "multipart/form-data",
            'Access-Control-Allow-Origin': '*',
          },
        })
          .then((response) => {
            navigate('result', { state: { isPentagrama: !!response.data?.accepted }})

            setIsLoading(false)
          })
          .catch((error) => {
            console.error(error)
            navigate('result', { state: { isPentagrama: false }})
          });
      })
  }

  const capture = useCallback(() => {
    const image = webcamRef.current.getScreenshot();
    eventTrack('tela foto', 'clique camera', 'Tirou-foto')

    onImageChange(image)
  }, [onImageChange]);
  

  return (
    isLoading ? (
      <div className='container'>
        <div className='loading'>
          <span>{i18n.t('summoning.loading')}</span>
          <img className='container-img' src={logoDiabloImg} alt="logoDiablo"/>
        </div>
      </div>
    ) : (
      <div className='container'> 
      <Webcam 
        ref={webcamRef}         
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        minScreenshotWidth={1080}
        minScreenshotHeight={720}
      >    
        {() => (
          <>
            <div className='box'>
              <img className='pentagram' src={pentagramaImg} alt='cam'/>
            </div>
            <button onClick={capture}></button>
          </>
        )}
      </Webcam>
    </div>
    )  
  )
}

export default Camera;