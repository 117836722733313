import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { i18n } from '../translate/i18n'
import { eventTrack } from '../utils/google-events';

import './result.css'

function Result() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state.isPentagrama)
      setTimeout(function() {
        window.location.replace('https://diablo-immortal.onelink.me/gyn9/ecluuymnl');
      }, 1000);
  }, [location.state.isPentagrama])

  const clickOnNavigate = () => {
    navigate('/snap')
    handleGoogleEventMenu('tela erro', 'clique novamente', 'invocar novamente')
  }

  const handleGoogleEventMenu = (category, action, label) => {
    eventTrack(category, action, label)
  }

  return (
    <div className='container'>
      <>
        {location.state.isPentagrama ? (
          <div className='content'>
            <span>
              {i18n.t('result.title')} <br />
              {i18n.t('result.subTitle')} <br />
              {i18n.t('result.subSubTitle')} <br />
              {i18n.t('result.subSubTitleTwo')}
            </span>
            <button className='result-button tutorial'>
              <a href='https://diablo-immortal.onelink.me/gyn9/ecluuymn'>
                {i18n.t('result.button')} <br />
                {i18n.t('result.subButton')}
              </a>
            </button>
          </div>
        ) : (
          <div className='content'>
            <span className='result'>
              {i18n.t('resultFailed.title')} <br />
              {i18n.t('resultFailed.subTitle')}  <br />
              {i18n.t('resultFailed.subSubTitle')}
            </span>
            <button onClick={handleGoogleEventMenu('tela erro', 'clique novamente', 'invocar novamente')} className='result-button tutorial'>
            <a href='https://diablo-immortal.onelink.me/gyn9/ecluuymn'>
              {i18n.t('resultFailed.secondaryButton')} <br />
              {i18n.t('resultFailed.secondaryButtonSubTitle')}
            </a>
            </button>
            <button className='result-button' onClick={clickOnNavigate}>
              {i18n.t('resultFailed.button')} <br />
              {i18n.t('resultFailed.subButton')}
            </button>
          </div>
        )}
      </>
    </div>
  );
}

export default Result;