const messages = {
  es: {
    translations: {
      home: {
        title: 'Escanea',
        subTitle: 'un pentagrama',
        subSubTitle: 'y entra',
        subSubSubTitle: 'al infierno.',
        desktopDescription: 'Los computadoras no tienen el poder de convocar al mal. Vuelve al infierno en tu smartphone.',
        description: 'puedes dibujar uno',
        descriptionOne: 'o usar cualquier',
        descriptionTwo: 'pentagrama existente',
        descriptionThree: 'para llamar',
        descriptionFor: 'a diablo.',
        buttonLabel: 'Invocar Diablo ahora',
        subButtonLabel: ''
      },
      summoning: {
        title: 'apunta tu celular al pentagrama',
        loading: 'Invocación'
      },
      result: {
        title: 'Diablo inmortal está listo',
        subTitle: 'para ser invocado',
        subSubTitle: 'en tu celular.',
        subSubTitleTwo: '',
        button: 'INVOCAR AHORA',
        subButton: ''
      },
      resultFailed: {
        title: 'Gente Mala',
        subTitle: 'entra al infierno',
        subSubTitle: 'pentagramas malos, no.',
        button: 'Intenta invocar nuevamente',
        subButton: '',
        secondaryButton: 'Ir a la tienda de apps',
        secondaryButtonSubTitle: ''
      },
      menu: {
        useTerms: 'términos de Uso'
      },
      tutorial: {
        title: 'Cómo hacer un pentagrama:',
        buttonTitle: 'Invocar Diablo ahora',
        subButtonTitle: ''
      }
    }
  }
}

export { messages }